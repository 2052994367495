import React from 'react';

export function useIsOffline() {
  const [offline, setOffline] = React.useState(false);
  React.useEffect(() => {
    const handleOnlineEvent = () => setOffline(false);
    const handleOfflineEvent = () => setOffline(true);
    window.addEventListener('online', handleOnlineEvent);
    window.addEventListener('offline', handleOfflineEvent);
    return () => {
      window.removeEventListener('online', handleOnlineEvent);
      window.removeEventListener('offline', handleOfflineEvent);
    };
  }, []);
  return offline;
}

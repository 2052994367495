import { useCallback, useRef } from 'react';
import type { MentiErrorOptions } from '@mentimeter/errors/sentry';
import { MentiError, captureException } from '@mentimeter/errors/sentry';

/**
 * This hook is used to debug infinite loop and rate limiting issues.
 * It will send errors to sentry if the rate limit is exceeded.
 * Do not rely on this hook to perform business logic
 */
export const useRateLimitWarning = (
  message: string | undefined,
  limit: number,
  timeFrameMS: number,
  reportOnce: boolean,
  errorOptions: MentiErrorOptions,
) => {
  const hasReported = useRef(false);
  const invocationCount = useRef(0);
  const firstInvocation = useRef<number | null>(null);

  return useCallback(() => {
    if (
      !firstInvocation.current ||
      Date.now() - firstInvocation.current > timeFrameMS
    ) {
      firstInvocation.current = Date.now();
      invocationCount.current = 0;
    }

    invocationCount.current++;

    if (
      invocationCount.current > limit &&
      (!reportOnce || !hasReported.current)
    ) {
      hasReported.current = true;
      firstInvocation.current = null;
      invocationCount.current = 0;
      if (process.env.NODE_ENV !== 'test') {
        captureException(
          new MentiError(message ?? 'Rate limit exceeded', {
            feature: 'rate-limiting',
          }),
        );
      }
    }
  }, [limit, message, reportOnce, timeFrameMS]);
};

export function toQueryParam(from: any): string {
  if (from === null) {
    return '';
  } else if (typeof from === 'object') {
    if (Array.isArray(from)) {
      return from.join(',');
    } else {
      return JSON.stringify(from);
    }
  } else {
    return String(from);
  }
}

import { useIdStore } from '@mentimeter/core-hooks/use-ids';
import { useRouter } from '@mentimeter/next-navigation';
import type {
  PresentationMode,
  PresentationRoute,
} from '@mentimeter/presentation-url-utils';
import {
  buildPresentationPath,
  parsePresentationUrl,
} from '@mentimeter/presentation-url-utils';
import { useCallback, useMemo } from 'react';

export {
  buildPresentationPath,
  parsePresentationUrl,
  type PresentationMode,
  type PresentationRoute,
};

/**
 * Reads window.location and returns the parsed current presentation route
 */
export function getCurrentPresentationRoute() {
  return parsePresentationUrl(window.location.href);
}

/**
 * Merges relative PresentationRoute changes with the current route for easy navigation,
 * for example when navigating to another question within the same presentation.
 */
function getUrl(route: Partial<PresentationRoute>) {
  const currentPresentationRoute = parsePresentationUrl(window.location.href);

  const currentUrl = new URL(window.location.href);
  const newPath = buildPresentationPath({
    ...currentPresentationRoute,
    ...route,
  });

  // No current query params, just return the new path
  if (currentUrl.search.length <= 0) return newPath;

  const searchStringStartIndex = newPath.indexOf('?');

  // We only want to inherit query params other than the ones controlled
  // by the route, so remove the session param.
  currentUrl.searchParams.delete('session');
  currentUrl.searchParams.delete('question');

  // No new query params, add the current params to the new path
  if (searchStringStartIndex < 0) return newPath + currentUrl.search;

  // Everything up until the ? is the path...
  const pathBase = newPath.slice(0, searchStringStartIndex);
  // ... everything after is the query params
  const pathSearch = newPath.slice(searchStringStartIndex);

  // Merge current and new params, with the new ones taking precedence
  const newSearchParams = new URLSearchParams(pathSearch);
  const mergedParams = new URLSearchParams({
    ...Object.fromEntries(currentUrl.searchParams),
    ...Object.fromEntries(newSearchParams),
  });

  return `${pathBase}?${mergedParams}`;
}

interface UseEditorRouter {
  navigateToSlide: (slideId: string, doReplace?: boolean) => void;
  goToPresentationView: (questionId?: string) => void;
  enablePreviewMode: (activeQuestionId?: string) => void;
}

export const useEditorRouter = (): UseEditorRouter => {
  const { push } = useRouter();
  const setQuestionId = useIdStore((state) => state.setQuestionId);

  const navigateToSlide = useCallback(
    (slideId: string, doReplace?: boolean) => {
      setQuestionId(slideId);
      if (doReplace) {
        window.history.replaceState(null, '', getUrl({ questionId: slideId }));
      } else {
        window.history.pushState(null, '', getUrl({ questionId: slideId }));
      }
    },
    [setQuestionId],
  );

  const goToPresentationView = useCallback(
    (questionId?: string) => {
      const { seriesId } = getCurrentPresentationRoute();

      const urlParams: PresentationRoute = {
        mode: 'present',
        session: null,
        seriesId,
      };
      if (questionId) urlParams.questionId = questionId;

      push(buildPresentationPath(urlParams));
    },
    [push],
  );

  const enablePreviewMode = useCallback(
    (activeQuestionId?: string) => {
      const pushUrl = getUrl({
        mode: 'present-preview',
        session: null,
        questionId: activeQuestionId,
      });
      push(pushUrl);
    },
    [push],
  );

  return useMemo(
    () => ({
      navigateToSlide,
      goToPresentationView,
      enablePreviewMode,
    }),
    [navigateToSlide, goToPresentationView, enablePreviewMode],
  );
};

import type { ProfanityLanguages } from '@mentimeter/editor-schema/api-types-overrides';

export const ASCII_EMOJI_CODE = 'ascii_emoji';
export const ALL_CODE = 'all';
const descriptionAsciiEmojiFilter =
  'Filters emojis and four or more continuous punctuation characters used to create inappropriate images.';

interface ProfanityItem {
  value: ProfanityLanguages;
  name: string;
  description?: string;
}

const profanityLanguages: Array<ProfanityItem> = [
  {
    value: ASCII_EMOJI_CODE,
    name: 'Ascii and emoji',
    description: descriptionAsciiEmojiFilter,
  },
  { value: 'hr', name: 'Croatian' },
  { value: 'nl', name: 'Dutch' },
  { value: 'en', name: 'English' },
  { value: 'fi', name: 'Finnish' },
  { value: 'fr', name: 'French' },
  { value: 'de', name: 'German' },
  { value: 'he', name: 'Hebrew' },
  { value: 'hi', name: 'Hindi' },
  { value: 'is', name: 'Icelandic' },
  { value: 'it', name: 'Italian' },
  { value: 'ja', name: 'Japanese' },
  { value: 'my', name: 'Malay' },
  { value: 'no', name: 'Norwegian' },
  { value: 'pt', name: 'Portuguese' },
  { value: 'ro', name: 'Romanian' },
  { value: 'ru', name: 'Russian' },
  { value: 'es', name: 'Spanish' },
  { value: 'sv', name: 'Swedish' },
];

export const PROFANITY_LANGUAGES = profanityLanguages;
export const PROFANITY_LANGUAGES_DELIMITER = ',';
